import { template as template_ef66c25da5244f31a6fb372a0bae59dd } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_ef66c25da5244f31a6fb372a0bae59dd(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
