import { template as template_e9ce39713e2a4bd48aa4fc3af54dd4ee } from "@ember/template-compiler";
const FKText = template_e9ce39713e2a4bd48aa4fc3af54dd4ee(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
