import { template as template_f7fc9dc70061468fa52b690dddecf350 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_f7fc9dc70061468fa52b690dddecf350(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
