import { template as template_7ecc9504d3f54f268ff5c3a5ab881ab1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7ecc9504d3f54f268ff5c3a5ab881ab1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
