import { template as template_d81ea1add58941f0b17d2e150f372fbf } from "@ember/template-compiler";
const FKLabel = template_d81ea1add58941f0b17d2e150f372fbf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
