import { template as template_33ae13c736a5427b8e02f0d93a45852e } from "@ember/template-compiler";
const FKControlMenuContainer = template_33ae13c736a5427b8e02f0d93a45852e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
